<template>
  <div>
    <a
      v-if="order.delivery_tracking_link"
      :href="generateTrackingLink(order)"
      target="_blank"
      >{{ order.short_delivery_order_id || order.delivery_order_id }}</a
    >
    <span v-else>{{
      order.short_delivery_order_id || order.delivery_order_id
    }}</span>
  </div>
</template>

<script>
export default {
  name: "DeliveryTracking",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    generateTrackingLink(item) {
      let link = null;
      const { delivery_tracking_link, delivery_order_id } = item;
      if (!delivery_tracking_link || !delivery_order_id) {
        return link;
      }
      if (delivery_tracking_link.includes("[delivery_order_id]")) {
        link = delivery_tracking_link.replace(
          "[delivery_order_id]",
          delivery_order_id
        );
      } else {
        link = `${delivery_tracking_link + delivery_order_id}`;
      }
      return link;
    },
  },
};
</script>

<style scoped></style>
